var chat_message_input = document.getElementById('chat-input');
function sendMessageAction(event){
    if(event.which == 13){
        addClientChatMessage();
    }
}
chat_message_input.removeEventListener('keypress', sendMessageAction);
/////////////////////////////////////
/////////////////////////////////////
$(document).on('click', '#burger-menu', openResponsiveEmergentMenu);
$(document).on('click', '#responsive-close-opt', closeResponsiveEmergentMenu);
$(document).on('click', '#responsive-menu .nav-list', closeResponsiveEmergentMenu);
$(document).on('click', '#chat-container #chat-button', openConversationChat);
$(document).on('click', '#chat-container #chat-close', closeConversationChat);
$(document).on('click', '#chat-conversation-container #chat-send', addClientChatMessage);
$(document).on('click', '#contact-form-container #contact-form-btn', sendContactEmail);
//CHAT VARIABLES
var chatInterval = null;
var chatIntervalTime = 5000;
var chatIntervalMaxRetry = 12;
var chatIntervalRetry = 0;
////////////////
$(document).ready(function(){
    if(!chat_message_input.hasEventListener){
        chat_message_input.addEventListener('keypress', sendMessageAction);
        chat_message_input.hasEventListener = true;
        getClientChatMessages();
    }
    if(!!blog_flag){
        getPagedBlogs();
    }
});
function ToggleMenu(){
    $('#menu-container').css('display', 'none');
    setTimeout(function(){
        if($('#menu-container').hasClass('menu-container-small')){
            $('#menu-container').removeClass('menu-container-small').addClass('menu-container-large');
        }else{
            $('#menu-container').removeClass('menu-container-large').addClass('menu-container-small');
        }
        $('#menu-container').css('display', 'block');
    }, 10);
}
function closeResponsiveEmergentMenu(){
    $('#responsive-menu').animate({
        left: '-100%'
    }, 500, function(){
        $('#responsive-menu').css('display', 'none');
    });
}
function openResponsiveEmergentMenu(){
    $('#responsive-menu').css('display', 'block').css('left', '-100%');
    $('#responsive-menu').animate({
        left: '0%'
    }, 500);
}
function openConversationChat(){
    $('#chat-conversation-container').slideDown(500, function(){
        scrollBottonChat();
    });
    $('#chat-container #chat-button').css('display', 'none');
}
function scrollBottonChat(){
    $('#chat-body #chat-messages-container').animate({
        scrollTop: $('#chat-messages-container').prop('scrollHeight')
    }, 200);
}
function closeConversationChat(){
    $('#chat-conversation-container').slideUp(500);
    $('#chat-container #chat-button').css('display', 'block');
}
function getClientChatMessages(){
    var DataSend = {
        client_chat_token: localStorage.getItem('client_chat_token')
    };
    PostMethodFunction(erp_url+'api/client/chat/getClientChatMessages', DataSend, null, setClientChatMessages, null);
}
function setClientChatMessages(response){
    //store reponse token on local storage
    if(response.client_chat_token != null){
        localStorage.setItem('client_chat_token', response.client_chat_token);
    }
    if(response.run_assistant_data != null){	
        //set interval to run assistant
        chatIntervalRetry = 0;
        $('#chat-comment-icon').addClass('fa-beat-fade');
        chatInterval = setInterval(function(){
            getRunAssistant(response.run_assistant_data);
        }, chatIntervalTime);
    }
    let appendContent = '';
    $.each(response.messages, function(index, message){
        appendContent += setMessageOnChatContainer(message);
    });
    $('#chat-conversation-container #chat-body #chat-messages-container').html(appendContent);
    scrollBottonChat();
    //window width
    if($(window).width()>768 && response.not_read>0){
        openConversationChat();
    }
}
function getRunAssistant(run_assistant_data){
    if(chatIntervalRetry < chatIntervalMaxRetry){
        chatIntervalRetry++;
        let DataSend = {
            thread_id: run_assistant_data.thread_id,
            run_id : run_assistant_data.id,
        };
        PostMethodFunction(erp_url+'api/client/chat/getRunAssistant', DataSend, null, function(response){
            if(response.data.status == 'completed'){
                clearChatInterval();
                saveNewThreadMessages();
            }
        }, null);
    }else{
        clearChatInterval();
    }
}
function clearChatInterval(){
    $('#chat-comment-icon').removeClass('fa-beat-fade');
    clearInterval(chatInterval);
}
function saveNewThreadMessages(){
    let DataSend = {};
    PostMethodFunction(erp_url+'api/client/chat/saveNewThreadMessages', DataSend, null, setClientChatMessages, null);

}
function setMessageOnChatContainer(message){
    let messageHTML = '';
    if(message.is_admin == 1){
        messageHTML += '<div class="chat-message-container robot d-flex" title="'+message.message+'">';
            messageHTML += '<img src="/images/robot.webp" class="chat-message-robot-img align-self-start" alt="Robot">';
            messageHTML += '<p class="chat-message-robot-text">'+message.message+'</p>';
        messageHTML += '</div>';
    }else{
        messageHTML += '<div class="chat-message-container user d-flex" title="'+message.message+'">';
            messageHTML += '<p class="chat-message-robot-text">'+message.message+'</p>';
        messageHTML += '</div>';
    }
    return messageHTML;
}
function addClientChatMessage(){
    let message = $('#chat-conversation-container #chat-input').val().replace(/(<([^>]+)>)/ig,"").replace(/(\r\n|\n|\r)/gm,"");
    if(message != ''){
        $('#chat-conversation-container #chat-body #chat-messages-container').append(setMessageOnChatContainer({'message':message, 'is_admin':0}));
        scrollBottonChat();
        let DataSend = {
            message: message
        };
        PostMethodFunction(erp_url+'api/client/chat/addClientChatMessage', DataSend, null, function(response){
            $('#chat-conversation-container #chat-input').val('');
            if(response.run_assistant_data != null){	
                //set interval to run assistant
                chatIntervalRetry = 0;
                $('#chat-comment-icon').addClass('fa-beat-fade');
                chatInterval = setInterval(function(){
                    getRunAssistant(response.run_assistant_data);
                }, chatIntervalTime);
            }
        }, null);
    }
    $('#chat-conversation-container #chat-input').val('');
        
}
function sendContactEmail(){
    /////////////////////////////////
    let flag = true;
    let name = $('#contact-form-container #contact-form-name').val();
    let company = $('#contact-form-container #contact-form-company').val();
    let email = $('#contact-form-container #contact-form-email').val();
    let phone = $('#contact-form-container #contact-form-phone').val();
    let message = $('#contact-form-container #contact-form-message').val();
    let terms = $('#contact-form-container #contact-form-terms').is(':checked');
    if(name == '' || name == null){
        flag = false;
        alertWarning('El campo nombre es obligatorio');
    }
    if(email == '' || email == null || !validateEmail(email)){
        flag = false;
        alertWarning('El campo email es obligatorio y debe ser un email válido');
    }
    if(phone == '' || phone == null){
        flag = false;
        alertWarning('El campo teléfono es obligatorio');
    }
    if(message == '' || message == null){
        flag = false;
        alertWarning('El campo mensaje es obligatorio');
    }
    if(!terms){
        flag = false;
        alertWarning('Debe aceptar los términos y condiciones');
    }
    if(flag){
        let contactButton = $('#contact-form-container #contact-form-btn');
        //disable button until response
        contactButton.attr('disabled', true);
        let DataSend = {
            name: name,
            company: company,
            email: email,
            phone: phone,
            message: message
        };
        PostMethodFunction('/Home/Contact/SendContactEmail', DataSend, null, function(response){
            //enable button
            contactButton.attr('disabled', false);
            /////////////////////////////////
            $('#contact-form-container #contact-form-name').val('');
            $('#contact-form-container #contact-form-company').val('');
            $('#contact-form-container #contact-form-email').val('');
            $('#contact-form-container #contact-form-phone').val('');
            $('#contact-form-container #contact-form-message').val('');
            $('#contact-form-container #contact-form-terms').prop('checked', false);
            swallMessage(
                'Mensaje enviado',
                'El mensaje se ha enviado correctamente',
                'success',
                null,
                null,
                3000,
                null
            )
            gtagSendEvent('https://ridder.com.co/');
        }, function(){
            contactButton.attr('disabled', false);
        });
    }
}
export function appendChatMessageToContainer(message){
    $('#chat-conversation-container #chat-body #chat-messages-container').append(setMessageOnChatContainer(message));
    scrollBottonChat();
}
function getPagedBlogs(){
    let DataSend = {
      lang: lang,
      pagination: {
        page: 1,
        size: 4,
        total: 0,
        totalPages:0,
        search: null
      }
    }
    PostMethodFunction(erp_url+'api/blog/get-page', DataSend, null, showPagedBlogs, null);
  }
  function showPagedBlogs(response){
    //console.log('showPageBlogs => ', response);
    /*set meta tag keywords*/
    let AppenedContent = '';
    $.each(response.data, function(index, value){
        AppenedContent += '<li class="blog-item">';
            AppenedContent += '<img src="'+value.image_path+'" alt="Ridder" class="blog-item-image">';
            AppenedContent += '<div class="blog-item-text">';
                AppenedContent += '<p class="blog-item-text-title">'+value.reduce_title+'</p>';
                AppenedContent += '<a href="/blogs/view/'+value.url+'" class="blog-item-text-link">'+(lang=='en'?'LEARN MORE':'LEER MÁS')+'</a>';
            AppenedContent += '</div>';
        AppenedContent += '</li>';
    });
    $('#blog-preview-section .blog-list').empty().append(AppenedContent);
  }